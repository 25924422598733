.input {
    background: #FEFCFF;
    border: 1px solid #BCB0B0;
    border-radius: 7px;
    margin: 5px;
    padding: 5px;
    height: 25px ;
    width: 300px;
    display: flex;
    justify-content: center;

    .placeholder{
        border: none;
        outline: none;
        width: 100%;
        font-family: 'Source Sans Pro';
        font-size: 15px;
        color: rgb(78, 78, 78);
    }
}