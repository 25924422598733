.navbar {
  height: 43px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  width: 100%;
  background: linear-gradient(90deg,  rgba(114, 67, 128, 0.90) 1.11%, #561f62 27.89%, #8B3678 65.63%, #e0bd0b 100%);
  border-bottom: 1px solid #FAD40C;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 82;


  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .logo {
      display: flex;
      align-items: center;
      height: auto;
    }

    .sair{
      margin-right: 20px;
      padding: 5px;
      display: flex;
      height: auto;
      align-items: center;
      color: #6B4380;
      background-color: #ffffff;
      border-radius: 5px;
      border: 1px solid  #FAD40C ;
      cursor: pointer;
    }

    .menu-buttons{
      background: rgba(255, 255, 246, 0.5); 
      border: none; 
      margin: auto 10px; 
      height: auto; 
      border-radius: 50%; 
      padding: 4px;
      height: auto; 
      width: auto; 
      color: #6B4380; 
      cursor: pointer; 
    }

    #toggleExpand{
      display: none;
    }
  }
}

@keyframes shake {
  0% { transform: translateY(0); }
  25% { transform: translateY(-5px); }
  50% { transform: translateY(5px); }
  75% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

.shake {
  animation: shake 0.5s;
}

.chip{
  background: rgb(255 255 246 / 50%) !important;
  align-content: center !important;
  height: auto !important;
  padding: 4px !important;
}

.chip:hover{
  background: rgb(255 255 246 / 30%) !important;
}

.notification{
  margin: auto; 
  margin-right: 10px; 
  flex-wrap: wrap;
  align-content: center
}

@media (max-width: 1200px) {
  #alert {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: rgb(107, 67, 128);
    border: 2px solid rgb(250, 212, 12);
    height: 100px;
    top: 40px;
    right: 0px;
    padding: 10px;
    justify-content: space-between;
  }

  #toggleExpand{
    display: flex !important; 
  }

  .notification{
    align-items: end;
    height: auto;
  }
}
