.pagina{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .cabeca{
        display: flex;
        height:auto;
    }

    .corpo-home{
        display: flex;
        flex-direction: row;
        height: 100vh;

        .tela-principal{
            margin-top: 43px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color:#6B4380;
            width: 100%;

            .texto-pagina-home{
                display: flex;
                font-size: 20px;
            }

            .icone-pagina-home{
                display: flex;
                align-items: flex-end;
                .icon-engineering{
                    font-size: 200px;
                }
            }


        }

    }
}