.tabs-container {
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        color: #6B4380;
        font-size: 13px;
    }

    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        font-size: 13px;
    }

    .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
        background-color: #6B4380;
    }
}

.container-table-financeiro{
    flex-grow: 1;
    align-items: flex-start;
    margin-left: 50px ;
}

.botaoConsultar {
    width: 90px;
    margin: 10px 0px;
    border-radius: 40px;
    height: 50px;
    background: rgb(31, 119, 252);
    color: white;
}

@media (max-width: 1200px) {
    .container-convenio{
        flex-direction: column !important;
        
        .capa-tabela{
            width: auto;
        }
    }
    .container-table-financeiro{
        margin-left: 0px;
    }

}
