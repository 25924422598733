.card-dash-extratos{
    margin-top: 100px;
    border-radius: 10px;
    background-color: #E8E8E8;
    padding: 20px;
    .valor{
        font-size: 25px;
    }
}

.total{
    height: 120px;
    padding-left: 5px;
}

.separator {
    width: 1px;
    background-color: #CFCED0;
    height: 100%; /* Adjust based on your layout */
    margin: 0px 8px;
    flex-grow: 1; 
}