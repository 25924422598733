.pagina{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .corpo{
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        height: 100vh;

        .tela-recuperar{
            width: 270px;
            height: 400px;
            flex-shrink: 0; 
            //padding-top: 40px;

            background: linear-gradient(153deg,  rgba(114, 67, 128, 0.90) 1.11%, #561f62 27.89%, #8B3678 65.63%, #FAD40C 100%);
            border-radius: 50px;
            border: 1px solid #FAD40C;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .lembrete{

                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                //padding-right: 50px;
                margin-left: -25px;
                padding-top:  40px;

                .frases{
                    text-decoration-line: underline; 
                    color: #EEECEF;
                    font-size: 12px;

                    .textos-lembretes{
                        height: auto;
                        margin-bottom: 2px;
                        cursor: pointer;
                    }
                }
            }

            .logo-id{
                width: 100%;
                margin-top: 40px;
                height: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .entrada{
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                height: 33%;

                .recuperar-infomacoes{
                    height: auto;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-end;
                    padding-left: 25px;
                    padding-right: 20px;
                    color: #EEECEF;
                    font-size: 11px;
                    font-weight:300;
                }

                .loader{
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: 90px;
                    justify-content: center;
                    display: flex;
                    position: fixed;
                    background-color: rgba(240, 248, 255, 0.699);
                }
            }
        }

    }
}