.pagina{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    .cabeca{
        display: flex;
        height:auto;
    }

    .corpo-home{
        
        display: flex;
        flex-direction: row;
        height: 100%;

        .conteudo-principal{
            display: contents;
        }
    }
}

.row{
    display: flex;
    flex-direction: row;
}

.column{
    display: flex;
    flex-direction: column;
}