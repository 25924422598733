#info {
    margin-top: 0px;
    margin-bottom: 15px;
    top: -15px;
}

.input{
    margin: 5px 0 15px 0;
}
#map{

}
#map .gm-style-cc {
    display: none !important;
  }