.main{
    display: contents;

    .menu-lateral-empresas{
        display: flex;
        padding: 40px 20px 20px 20px;
        margin-top: 43px;
        flex-direction: column;
        color: #5a595c;

    }
    
    .tela-principal-retornos{
        display: flex;
        padding: 40px 20px 20px 20px;
        margin-top: 43px;
        flex-direction: column;
        width: 100%;
        position: relative;
        overflow-y: auto;
        color: #5a595c;

        .loader-pay{
            top: 10%;
            width: 100%;
            right: 2%;
            height: 60px;
            justify-content: center;
            display: flex;
            //background-color: #ffff; // rgb(255, 255, 255);
            position: fixed;
            border-radius: 8px;
            z-index: 100;
        }


        .abraca-conteudo-interno{
            overflow-x: clip;
            height: auto;
            width: 100%;
            //display: flex;
            flex-direction: row;
        }
    
        .titulo-grandes-entregas{
            display: flex;
            height: auto;
            width: 100%;
            margin-bottom: 15px;
    
            .texto-titulo-Grandes-encomendas{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                color: #5a595c;
            }

            .tabs-container{
                margin-left: auto;
                .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
                    color: #6B4380;
                    font-size: 13px;
                }
                .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
                    font-size: 13px;
                }
                .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator{
                    background-color: #6B4380;
                }
            }
        }
    
        .botao-coleta{
            display: flex;
            height: auto;
            margin: auto 0px auto auto;
            justify-content: flex-end;
        }
    
        .menu-status-entregas{
            display: flex;
            justify-content: center;
            margin: 5px 0px 20px 30px;
    
            .lista-grades-entregas{
                margin-top: 15px;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0px 10px 0px 10px;
            }
        }

        .table-title{
            th{
                color: rgb(78, 78, 78);
            }
        }

        .table-footer {
            margin: auto;
            display: flex;
            position: relative;
            width: max-content;
            right: 100%;
            left: 100%;

        }

        .flex-line{
            flex-direction: row;
        }

        @media (max-width: 1200px) {
            .titulo-grandes-entregas{
                flex-direction: column;
                
                .texto-titulo-Grandes-encomendas{
                    justify-content: start;
                }
            }

            .abraca-conteudo-interno{
                flex-direction: column;
                
                .center{
                    margin: auto;
                }
            }
        }
    
        @media (max-width: 700px) {
            .menu-status-entregas{
                flex-direction: column;
                margin: 5px;
            }
        }
        
        @media (max-width: 2500px) {
            .marieta{
                font-size: 0.85em;
            }
        }
    }
}