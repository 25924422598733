.lista-itens{
    padding: 15px;
    width: 100%;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    
    .borda-historico{

        border-radius: 8px;
        border: 1px solid #BCB0B0;
        padding: 10px;
        margin-top: 10px;
        margin-right: 10px;
        .cabeca-historico{
            display: flex;
            width: 500px;
            justify-content: space-around;
            border-bottom:1px solid #BCB0B0;
            color:rgb(29, 28, 28);
            
        }
        .corpo-historico{
            display: flex;
            width: 500px;
            justify-content: space-between;
            border-bottom:1px solid #e0dbdb;
            color: rgb(78, 78, 78);
            padding: 0px 5px 0px 5px;

            height: auto;
    
        }
    }
    .item{
        padding: 5px;
        display: flex;
        box-shadow: 0px 2px 1px 0px rgba(219, 213, 213, 0.2),0px 1px 1px 0px rgba(143, 140, 140, 0.14),0px 1px 3px 0px rgba(0,0,0,0.12);


        .titulo{
            margin-right: 10px;

        }
        .valor{
            color: rgb(78, 78, 78);

        }
    }
}