.pagina{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    .cabeca{
        display: flex;
        height:auto;
    }

    .corpo-home{
        
        display: flex;
        flex-direction: row;
        height: 100vh;
        width: 100%;
        align-self: flex-end;
    }
}

@media (min-width: 1200px) {
    .corpo-home{
        width: calc(100% - 100px) !important;
    }
}