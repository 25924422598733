.popup .popup-inner{
    width: -moz-fit-content;
    width: fit-content;
    max-width: 400px;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 15px;
    .popUpConteudo{
        width:  80vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        .popUpConteudo-2{
            width: fit-content;
        }
    }
}

.buttonRegistrar{
    display: flex;
    width: 300px;
}