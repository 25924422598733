.tela-lateral {
    width: 250px;
    background-color: rgb(107, 67, 128);
    border: 2px solid rgb(250, 212, 12);
    position: fixed;
    top: 0px;
    left: 0px;
    margin-top: 43px;
    height: calc(100vh - 43px);
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 20px;
    transition: width 0.3s ease;
    z-index: 50;
    overflow: hidden;
    
    &.collapsed {
        width: 70px;
    }
    
    .toggle-button {
        align-self: flex-end;
        margin-bottom: 20px;
        color: #fff;
        height: fit-content;
    }

    .itens-recarga{
        /*height: fit-content;
        padding-top: 10px;
        display: flex;
        align-items: flex-end;
        height: 100%;*/

        .bloco-recarga {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            height: fit-content;
            cursor: pointer;
            padding: 10px;
            border-radius: 8px;
            transition: background-color 0.3s, color 0.3s;
    
            &:hover {
                background-color: #fad40c;
            }
    
            &.active {
                background-color: #8b3678;
            }
    
            .bloco-icone {
                color: rgb(107, 67, 128);
                background-color: #fad40c;
                border-radius: 50%;
                padding: 8px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .titulo {
                font-size: 17px;
                color: #fff;
                transition: opacity 0.3s;
    
                // Hide the text smoothly when collapsed
                opacity: 1;
            }
        }
    }
       
    .itens-menu{
        height: fit-content;
        padding-top: 10px;

        .bloco-clicavel {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            height: fit-content;
            cursor: pointer;
            padding: 10px;
            border-radius: 8px;
            transition: background-color 0.3s, color 0.3s;
    
            &:hover {
                background-color: #fad40c;
            }
    
            &.active {
                background-color: #8b3678;
            }
    
            .bloco-icone {
                color: rgb(107, 67, 128);
                background-color: #fad40c;
                border-radius: 50%;
                padding: 8px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .titulo {
                font-size: 17px;
                color: #fff;
                transition: opacity 0.3s;
    
                // Hide the text smoothly when collapsed
                opacity: 1;
            }
        }
    }


    &.collapsed .titulo {
        opacity: 0;
    }
}


@media (max-width: 1200px) {
    .tela-lateral {
        top: 0px;
        right: 0px;
        margin-left: auto;
        height: auto;
    }
}