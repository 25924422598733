.title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #5a595c;
  }

.icon {
    width: 30px;
    height: auto;
    color: #6B4380;
    margin-right: 8px; /* Add some space between the icon and the text */
  }
  
  .p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .h3 {
    font-size: 1.75rem;
    font-weight: bold;
  }  

  @media (max-width: 1200px) {
    .title {
      justify-content: start;   
    }
  }