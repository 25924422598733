#description {
    font-size: 15px;
    font-weight: 300;
  }
  
  #infowindow-content .title {
    font-weight: bold;
  }
  
  #infowindow-content {
    display: none;
  }
  
  #map #infowindow-content {
    display: inline;
  }
  
  .pac-card {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 0 0.5em;
    font: 400 18px Roboto, Arial, sans-serif;
    overflow: hidden;
    padding: 0;
    z-index: 10;
  }
  
  #pac-container {
    padding-top: 10px;
    margin-right: 12px;
    overflow: hidden;
  }
  
  .pac-controls {
    display: inline-block;
    padding: 5px 11px;
  }
  
  .pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
  }
  
  #pac-input {
    background-color: #fff;
    font-size: 15px;
    padding: 0 11px 0 1px;
    text-overflow: ellipsis;
    width: 400px;
    color: rgb(78, 78, 78);
    font-family: 'Source Sans Pro'
  }
  
  #pac-input:focus {
    border-color: #4d90fe;
  }
  
  #title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
  }
  
  #target {
    width: 345px;
  }
  