.select {

    background: #FEFCFF;
    border: 1px solid #BCB0B0;
    border-radius: 7px;
    margin: 5px;
    padding: 5px;
    height: 25px ;
    width: 150px;
    cursor: pointer;

    .place{
        background: transparent;
        border: none;
        width: 100%;
        height: 100%;
        font-size: 15px;
        color: rgb(78, 78, 78);
        cursor: pointer;
    }
}