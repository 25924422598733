.main{
    display: contents;

    .loader{
        top: 50%;
        left: 0;
        width: 100%;
        height: 70px;
        justify-content: center;
        display: flex;
        position: fixed;
    }
    
    .tela-principal-grandes-encomendas{
        display: flex;
        margin-top: 43px;
        padding: 40px 20px 20px 20px;
        flex-direction: column;
        width: 100%;
        position: relative;
        overflow-x:hidden;
        overflow-y:auto;
        color:#6B4380;
        
    
        .titulo-grandes-entregas{
            display: flex;
            height: auto;
            width: auto;
            margin-bottom: 15px;

    
            .texto-titulo-Grandes-encomendas{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                color: #5a595c;
                margin-bottom: 20px;
            }
        }
    
        .botao-coleta{
            display: flex;
            height: auto;
            margin: auto 0px auto auto;
            justify-content: flex-end;
        }

        .botoes-filtro{
            margin-top: 15px;
            margin-left: 20px;
            font-size: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            color: rgb(78, 78, 78);

            .botao{
                padding: 10px;
                border-bottom: 2px solid #6B4380;
                margin-left: 5px;
                cursor: pointer;
            }

            .botao-atualiza{
                display: flex;
                align-items: center;
                margin-right: 25px;
                .toggle_on{
                    font-size: 30px;
                    color: rgb(66, 175, 60);
                    cursor: pointer;
                }
                .toggle_off{
                    font-size: 30px;
                    cursor: pointer;
                }
            }
        }
    
        .menu-status-entregas{
            display: flex;
            justify-content: center;
            margin: 5px 0px 20px 30px;
    
            .lista-grades-entregas{
                margin-top: 15px;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0px 10px 0px 10px;
            }
        }
    
        @media (max-width: 700px) {
            .menu-status-entregas{
                flex-direction: column;
                margin: 5px;
            }
    
        }
    }

    .popUp-infos-linha{
        padding-right: 15px;

        .loader{
            top: 50%;
            left: 0;
            width: 100%;
            height: 70px;
            justify-content: center;
            display: flex;
            position: fixed;
        }
        
        .titulo-entrega{
            font-size: 25px;
            color:#6B4380; 
            margin-bottom: 20px;
            margin-left: 10px;
        }
    }

    .popUp-cancel{

        .texto-cancel{
            margin-right: 30px;
        }

        .botoes-cancel{
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
        }
    }

    .popUp-filtro-cargo{
        .title-filter{
            font-size: 25px;
            color:#6B4380; 
            margin-bottom: 20px;
        }

        .loader{
            top: 50%;
            left: 0;
            width: 100%;
            height: 70px;
            justify-content: center;
            display: flex;
            position: fixed;
        }

        .itens-filtro{
            .formulario-filtro{
                margin-bottom: 20px;
            }

            .select-cargo{
                margin-bottom: 20px;
            }

            .filtro-data{
                margin-bottom: 20px;
                margin-left: 10px;
                color: rgb(78, 78, 78);
            }

            .botao-filtro-cargo{
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}