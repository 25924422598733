


.popUp-credito{
    width: 800px;
    height: 450px;
    overflow: hidden;
    .div100{
        width: 100%;
        display: flex;
        flex-direction: column;

        .loader{
            top: 50%;
            left: 0;
            width: 100%;
            height: 70px;
            justify-content: center;
            display: flex;
            position: fixed;
        }
        .conteudo {
            .textenado{
                display: flex;
                flex-direction: column;
                height: auto;
                width: auto;

    
                .texto-titulo-credito{
                    font-size: 23px;
                    color: #6B4380;
                    margin-bottom: 30px;
                }
                .texto-bloco-credito{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-left: 5px;
                    font-size: 16px;
                    color:rgba(0, 0, 0, 0.6);

                    .abraca-itens{
                        display: flex;
                            
                        .texto-itens{
                            margin-top: 30px;
                            width: 50%;
                            .linha-resumo{
                                
                                display: flex;
                                flex-direction: row;
        
                                .linha-titulo-resumo{
                                    color: rgb(78, 78, 78);
                                    margin-right: 5px;
                                }
                                .linha-final-resumo{
                                    color: rgb(78, 78, 78);
                                    margin-right: 5px;
                                    margin-top: 20px;
                                    margin-bottom: 10px;
                                }
                            }

                        }
                        .icone-texto{
                            width:50%;
                            display: flex;
                            justify-content: center;
                        }
                        
                    }

                    .linha-resumo{
                                
                        display: flex;
                        flex-direction: row;

                        .linha-titulo-resumo{
                            color: rgb(78, 78, 78);
                            margin-right: 5px;
                        }
                        .linha-final-resumo{
                            color: rgb(78, 78, 78);
                            margin-right: 5px;
                            margin-top: 20px;
                            margin-bottom: 10px;
                        }
                    }
                

                    .bloco-pagamento-credito{
                        .bloco-pagamento{
                            display: flex;
                            color: #fff;
                            border: 1px solid #6B4380;
                            background-color: #6B4380;
                            border-radius: 3px;
                            margin: 5px;
                            width: 150px;
                            cursor: pointer;
                            font-size: 15px;
                            padding: 5px;
                            justify-content: center;
                            align-items: center;
                        }

                    }
                }


            }
            .qr-code-credito{
                display: flex;
                flex-direction: column;
                width: auto;
                height: auto;
            }

        }
        
    }

}

    .botoes-popup-credito{
        height: auto;
        display: flex ;
        justify-content: flex-end;
        align-items: flex-end;
        .botoes-credito{
            display: flex;
            justify-content: flex-end;
        }

    }

@media (max-width: 860px), (max-height: 580px) {
    .popUp-credito{
        width: auto;
        height: auto;
        .div100 .conteudo .textenado .texto-bloco-credito .abraca-itens .icone-texto{
            display: none;
        }

        .div100.loader.icone{
            width: auto;
            height: auto;
        }
    }
}