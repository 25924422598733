.button {
    color: #6B4380;
    border: 1px solid #BCB0B0;
    border-radius: 8px;
    margin: 5px;
    height: 37px ;
    width: 60px;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Gabarito', sans-serif;
    transition: transform 0.2s ease-in-out;

}

.btn_selected{
    background-color: #6B4380;
    color: #FEFCFF;
    border: 1px solid #6B4380;
}

button:disabled {
    background-color: gray !important;
    border-color: #6B4380 !important;
}

.button:hover {
    background-color: #6B4380;
    color: #FEFCFF;                                                                       
}

.button:active {
transform: translateY(1px);
}

.danger{
    color: red;
    border: 1px solid red;
}

.danger:hover {
    background-color: red;
    color: #FEFCFF;                                                                       
}