.main{
    display: contents;
    
    .tela-principal-mapa{
        display: flex;
        padding: 40px 20px 20px 20px;
        margin-top: 43px;
        flex-direction: column;
        color:#6B4380;
        width: 100%;
        position: relative;
        max-height: 800px;
        overflow-y: auto;    
    }
}