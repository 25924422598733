.rastreio{
    background-image: url("./Imagens/cidade.png");
    background-repeat: repeat-x;
    background-size: auto 70px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 100%;

    .cabeca{
        width: 100%;
        margin-bottom: 40px;
        height: auto;
        .icone{
            width: 150px;
            height: 50px;
        }

        .faxada{
            display: flex;
            justify-content: space-between;
            width: auto;
            height: auto;
            margin-top: 40px;
            margin-left: 25px;
            margin-right: 20px;
            color: #565556;

            .texto{
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                color:  #565556;
                // color:  #222122;
                //background-color: #adadad3b;
            }
            .imagem-furgao{
                .icone-furgao{
                    width: 140px;
                    height: 50px;
                }
            }
        }
    }
    .abraca-corpo{
        display: flex;
        flex-direction: column;
        width: auto;
        //height: auto;
        //justify-content: center;
        //align-items: center;


        .corpo-rastreio-imagens{
            display: flex;
            flex-direction: row;
            .botoes-fotos{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                height: auto;
                align-items: center;

                .botao{
                    padding: 10px;
                    border: 1px solid #1976d2;
                    border-radius: 8px;
                    color: #1976d2;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: auto;
                    cursor: pointer;

                    .icone-imagem{
                        color: #1976d2;
                    }
                    .titulo{
                        height: auto;
                    }
                }
            }
        }
        .corpo-rastreio{
            margin-top: 20px;
            margin-left: 20px;
            margin-right: 20px;
            border-radius: 8px;
            background-color: #adadad10;
            padding: 10px;
            display: flex;
            flex-direction: column;
            height: auto;

            .data{
                display: flex;
                margin-bottom: 10px;
                display: flex;
                height: auto;

                .texto-data{
                    font-size: 13px;
                    color: #999;
                }
                .icone-relogio{
                    font-size: 17px;
                    margin-left: 5px;
                    color:  #999;

                }
            }

            .blocos-endereco{
                color:    #565556;
                height: auto;
                .titulo{
                    color:  #222122;
                }

            }

        }

    }
}

.borda-historico{

    border-radius: 8px;
    border: 1px solid #BCB0B0;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
    .cabeca-historico{
        display: flex;
        width: 500px;
        justify-content: space-around;
        border-bottom:1px solid #BCB0B0;
        color:rgb(29, 28, 28);
        
    }
    .corpo-historico{
        display: flex;
        width: 500px;
        justify-content: space-between;
        border-bottom:1px solid #e0dbdb;
        color: rgb(78, 78, 78);
        padding: 0px 5px 0px 5px;

        height: auto;

    }
}


@media (max-width: 500px) {
    .rastreio{
        display: flex;
    }
}
