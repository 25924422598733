.even-row {
    background-color: rgba(139, 54, 120, 0.2);
}

.odd-row {
    background-color: #ffffff;
}

.taable .td {
    font-family: 'Gabarito', sans-serif;
    font-size: 15px;
    color: rgb(78, 78, 78);
    text-align: center !important;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #F2F2F2;
}

.taable .td .box {
    border-radius: 7px;
}
