.capa-tabela{
    width: 100%;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

    .loader{
        top: 50%;
        left: 0;
        width: 100%;
        height: 70px;
        justify-content: center;
        display: flex;
        position: fixed;
    }
    .tabela-generica{
        width: 100%;
        background: #ffffff;
        padding: 0px 0px 0px 0px;
        margin: 10px;
        background-color: transparent;
        font-family: 'Gabarito', sans-serif;
        .head{
            font-family: 'Gabarito', sans-serif;
            font-size: 15px;
            color: rgb(78, 78, 78);
            background-color: transparent;
            border-bottom: 1px solid black;
            box-shadow: 0px 2px 1px 0px rgba(219, 213, 213, 0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

            .th{
                background-color: transparent;
                border-bottom: 1px solid #F2F2F2;
                text-align: left;

            }
        }

        .tr{
            border-bottom: 1px solid rgba(0, 0, 0, 0.6);
            box-shadow: 0px 2px 1px 0px rgba(219, 213, 213, 0.2),0px 1px 1px 0px rgba(143, 140, 140, 0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
            height: 40px;
        }
        .td{
            font-family: 'Gabarito', sans-serif;
            font-size: 15px;
            color: rgb(78, 78, 78);
            text-align: left;
            align-items: center;
            justify-content: center;

            .material-icons{
                color:  rgb(107, 67, 128);
                cursor: pointer;
                
            }
            .box{
                border-radius: 7px;
            }

        }
    }
}
.buttons{
    display: flex;
    justify-content: flex-end; //cetraliza verticalmente
}