.bloco-repete{

    .blocos-status{
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 25px;
        height: auto;

        .icone-status{
            position:relative;
            width: 15%;
            .icone-place{
                color: #999;
               // color: rgb(66, 175, 60);
                font-size: 35px;
            }

        }
        .icone-status::after{
            content:'';
            display: block;
            position: absolute;
            left: 16px;
            border: 1px dashed #999;
            height: 20px;
        }

        .icone-status-verde{
            position:relative;
            width: 15%;
            .icone-place{
                color: rgb(66, 175, 60);
                font-size: 35px;
            }

        }
        .icone-status-verde::after{
            content:'';
            display: block;
            position: absolute;
            left: 16px;
            border: 1px dashed rgb(66, 175, 60);
            height: 20px;
        }

        .icone-aguarda{
            font-size: 35px;
            color:  #999;
        }

        .texto-status{
            width: 80%;
            padding: 10px;
            border: 1px solid #999;
            border-radius: 8px;
            display: flex;
            color: #565556;

        }

    }
}